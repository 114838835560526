<h2>Búsqueda</h2>

<form class="form-inline" action="/blog/search" method="get">
  <div class="md-form mt-0">
    <input class="form-control mr-sm-2" type="search" placeholder="Búscar entradas" name="q" (keypress)="onKeyPress($event);">
  </div>
</form>


<hr style="margin: 2vh 2vw;"/>

<h2>Autores</h2>
<h4 *ngFor="let author of authors | keyvalue">
  <span style="float: right; font-size: 80%;">{{author.value}} entradas</span>
  <a class="color-blue-a700" [href]="'/blog/authored/' + author.key" style="text-decoration: none;">{{author.key}}</a>
</h4>

<hr style="margin: 2vh 2vw;"/>

<h2>Etiquetas</h2>
<h4 *ngFor="let tag of tags | keyvalue">
  <span style="float: right; font-size: 80%;">{{tag.value}} entradas</span>
  <a class="color-blue-a700" [href]="'/blog/tagged/' + tag.key" style="text-decoration: none;">{{tag.key}}</a>
</h4>

<hr style="margin: 2vh 2vw;"/>

<h2>Últimas entradas</h2>
<h4 *ngFor="let article of entries | slice:0:3">
  <a class="color-blue-a700" [innerText]="article.title" [href]="'/blog/article/' + article.article" style="text-decoration: none;"></a>
</h4>
