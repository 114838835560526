import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { ContactService } from '../contact.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private contacts: ContactService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  openContactFormDialog() {
    const dialogRef = this.dialog.open(ContactFormComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`, result);
      if(result && result.send==='ok') {
        this.contacts.create(result.data).subscribe((response) => {
          console.log(response)
          this.snackBar.open('Su consulta ha sido registrada. Pronto tendrá noticias!.', 'ok!', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          })
        })
      }
    })
  }
}
