import { Component, OnInit } from '@angular/core';
import { BlogService } from '../blog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { formatDate } from '@angular/common';
import { BlogEntry } from '../blog-entry';

@Component({
  selector: 'app-blog-main-side-panel',
  templateUrl: './blog-main-side-panel.component.html',
  styleUrls: ['./blog-main-side-panel.component.scss']
})
export class BlogMainSidePanelComponent implements OnInit {
  public entries: BlogEntry[] = [];
  public tags = new Object();
  public authors = new Object();

  constructor(
    private route: ActivatedRoute,
    private blogSvc: BlogService
  ) {
    this.blogSvc.getBlogEntries().subscribe((entries:BlogEntry[]) => {
      this.entries = entries.map(entry => {
        entry.tags.map(tag=>{
          if (this.tags[tag]===undefined) this.tags[tag] = 0;
          this.tags[tag]++;
        })

        if (this.authors[entry.author_name]===undefined) this.authors[entry.author_name] = 0;
        this.authors[entry.author_name]++;

        return entry
      })
    })
  }

  ngOnInit(): void {
  }

  onKeyPress(event:KeyboardEvent) {
    if(event.code==="Enter") {
      (event.target as HTMLInputElement).form.submit();
    }
  }
}
