<div id="firstContainer" style="padding: 10vh 15vw; background: url(/assets/img/services-background.png); background-size: cover; background-position: center;">
  <div fxLayout="row wrap" fxLayoutAlign="center center" id="part01">
    <div fxFlex="1 1 80%" class="color-white">
      <h1 style="font-weight: 600;">Servicios</h1>
      <h3><strong class="color-red-a100">TODO </strong>Cuando piensa en el desarrollo de software, ¿qué le viene a la cabeza?.</h3>
      <h3><strong class="color-red-a100">TODO </strong>En Sinergia Tecnología y Servicios Software pensamos en el diseño de soluciones de software personalizadas que se adaptan a los distintos problemas empresariales que nos presentan nuestros clientes.</h3>
    </div>
    <button fxFlex="1 1 220px" mat-raised-button color="primary" class="stroked-blue-400" disableRipple="true" isRoundButton="true" (click)="openContactFormDialog();">Háblenos de su idea</button>
  </div>
</div>

<div style="padding: 5vh 15vw;" class="color-grey-800">
  <h1 style="font-weight: 800;">Desarrollo de software</h1>
  <h3>Cuando piensa en el desarrollo de software, ¿qué le viene a la cabeza?.</h3>
  <h3>En Sinergia Tecnología y Servicios Software pensamos en el diseño de soluciones de software personalizadas que se adaptan a los distintos problemas empresariales que nos presentan nuestros clientes.</h3>
  <h3>Identificamos los problemas técnicos específicos de su idea de negocio, realizamos un análisis exhaustivo para encontrar soluciones y las ejecutamos mediante un proceso transparente y de colaboración.</h3>
  <h3>El producto final es un software que responde a las necesidades de su empresa de forma eficiente.</h3>
</div>

<div style="padding: 2vh 15vw;" class="color-grey-800">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <img src="/assets/img/web-bussiness-apps.png" fxFlex.xs="100px" fxFlex="150px">
    <div fxFlex.xs="1 1 auto" fxFlex style="margin: 0vh 2vw;">
      <h2 class="color-grey-800" style="font-weight: 800;">Aplicaciones web empresariales</h2>
      <h3 class="color-grey-800">Nuestro experto equipo de desarrollo diseña soluciones personalizadas que impulsen el éxito empresarial, haciendo que los procesos de negocio sean más eficientes, mejorando la experiencia del usuario y generando más beneficios a nuestros clientes.</h3>
    </div>
  </div>
</div>

<div style="padding: 2vh 15vw;" class="color-grey-800">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <img src="/assets/img/saas-web-apps.png" fxFlex.xs="100px" fxFlex="150px">
    <div fxFlex.xs="1 1 auto" fxFlex style="margin: 0vh 2vw;">
      <h2 class="color-grey-800" style="font-weight: 800;">Aplicaciones en la nube (SAAS)</h2>
      <h3 class="color-grey-800">Sabemos que confía en la seguridad, el alto rendimiento y la rentabilidad de hacer las cosas en línea. Por eso todo el software que desarrollamos se ejecuta en cualquiera de sus sistemas “cloud” preferidos.<br/>Tanto si trabaja en la oficina, en casa o de camino al aeropuerto, puede gestionar su negocio en línea. Automatizamos sistemas complejos, haciéndolos sencillos de usar y mantener sin importar dónde se encuentre.</h3>
    </div>
  </div>
</div>

<div style="padding: 2vh 15vw;" class="color-grey-800">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <img src="/assets/img/multi-platform-apps.png" fxFlex.xs="100px" fxFlex="150px">
    <div fxFlex.xs="1 1 auto" fxFlex style="margin: 0vh 2vw;">
      <h2 class="color-grey-800" style="font-weight: 800;">Aplicaciones multiplataforma</h2>
      <h3 class="color-grey-800">Creamos soluciones amigables que sean fáciles de usar en cualquier plataforma. Nuestros clientes no son todos iguales. Algunos optan por trabajar desde sus teléfonos y tabletas, otros prefieren usar sus portátiles, por ello proporcionamos soluciones multidispositivo capaces de brindar a los usuarios una experiencia coherente en todas las plataformas, tanto si están en el trabajo, en el autobús o descansando en el sofá.</h3>
    </div>
  </div>
</div>

<div style="padding: 2vh 15vw;" class="color-grey-800">

  <h3><strong class="color-red-a100">TODO </strong>Desarrollo ágil</h3>
  <div fxLayout="row" fxLayoutAlign="center center">
    <img src="/assets/img/grafico-desarrollo-agil.png" style="width: 75%; max-width: 75%; margin: 0 auto;"/>
  </div>
  <h3>Trabaje con nuestros desarrolladores full-stack.</h3>
  <h3>Nuestros desarrolladores destacan tanto en el frontend como en el backend utilizando las últimas tecnologías y las mejores prácticas, ofreciendo una experiencia única a nuestros clientes.</h3>
  <h3 style="font-weight: 500;">Nuestro “stack” tecnológico</h3>
  <div style="padding: 2vh 5vw;" fxLayout="row wrap" fxLayoutAlign="center center">
    <img fxFlex="60%" fxFlex.xs="75%" fxHide fxShow.sm fxShow.xs src="/assets/img/Stack-2-columnas-1.png"/>
    <img fxFlex="60%" fxFlex.xs="75%" fxHide fxShow.sm fxShow.xs src="/assets/img/Stack-2-columnas-2.png"/>
    <img fxFlex="75%" fxHide fxShow.md fxShow.lg fxShow.xl src="/assets/img/Stack-5-columnas.png"/>
  </div>
  <!--
  <div style="padding: 2vh 10vw;" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <img src="/assets/img/stack-angular.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-bootstrap.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-css3.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-go.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-html5.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-ionic.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-jquery.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-linux.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-mssql.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-oracle.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-php.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-react.png" style="margin: 0vh 5vw;"/>
    <img src="/assets/img/stack-typescript.png" style="margin: 0vh 5vw;"/>
  </div>
  -->
</div>

<div style="padding: 5vh 15vw;" id="part02">
  <h1 class="color-grey-800" style="font-weight: 800;">Consultoría tecnológica</h1>
  <h3 class="color-grey-800">Le asesoramos sobre la tecnología adecuada a sus necesidades y retos.</h3>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h3 class="color-grey-800" style="font-weight: 100; margin: 0 2vw 1vh 0;" fxFlex.xs="1 1 auto" fxFlex.sm="1 1 auto" fxFlex>Nos asociamos con nuestros clientes para comprender su contexto empresarial único.<br>En ese contexto analizamos y evaluamos el estado tecnológico de la empresa con el objetivo de proponer mejoras y nuevas soluciones que le permitan ahorrar tiempo y dinero, optimizando procesos y recursos.<br/>Nuestros consultores tecnológicos asesoran a su empresa para que utilice el mejor software y hardware de acorde a sus necesidades y presupuesto, guiándole en la implementación de estas nuevas herramientas y formando a sus empleados en su uso.</h3>
    <img src="/assets/img/process.png" fxFlex.xs="100%" fxFlex.sm="75%" fxFlex="250px"/>
  </div>
</div>

<div style="padding: 5vh 15vw; background-color: #003358;" id="part03" class="color-white">
  <h1 style="font-weight: 800;">Operaciones y mantenimiento</h1>
  <h3>Podemos ayudarle a mantener y optimizar sus sistemas.</h3>
  <div fxLayout="row wrap" fxLayoutAlign="center center" style="margin: 2vh 0vw;">
    <img src="/assets/img/firefighter.png" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex="1 1 25%">
    <div fxFlex.xs="1 1 auto" fxFlex style="margin: 0vh 2vw;">
      <h3 style="font-weight: 500;">Servicio de rescate de proyectos de software</h3>
      <h3>¿Su software no está respondiendo como se supone que debe hacerlo?<br/>Desde el mantenimiento de su sistema de software actual, hasta el rescate de un proyecto fallido, podemos ayudarle.</h3>
      <h3>Damos nueva vida a los proyectos en dificultades. Nuestro equipo de desarrollo puede tomar el relevo y volver a poner su proyecto en marcha mejorando su rendimiento y ampliando la funcionalidad de su aplicación heredada.</h3>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center" style="margin: 2vh 0vw;">
    <img src="/assets/img/computer.png" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex="25%">
    <div fxFlex.xs="1 1 auto" fxFlex style="margin: 0vh 2vw;">
      <h3 style="font-weight: 500;">Mantenimiento y soporte</h3>
      <h3>Ofrecemos un servicio de mantenimiento preventivo integral de sus sistemas informáticos que garantice su correcto funcionamiento en todo momento.</h3>
      <h3>Diseñamos estrategias de mantenimiento basadas en la prevención y la asistencia inmediata. Tenemos amplia experiencia en la instalación y mantenimiento de servidores y redes informáticas para empresas.</h3>
    </div>
  </div>
</div>

