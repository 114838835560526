import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow
} from 'swiper/core';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { ContactService } from '../contact.service';
import {MatSnackBar} from '@angular/material/snack-bar';
// import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow,
  EffectFade,
  EffectCube,
  EffectFlip]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private contacts: ContactService,
    private snackBar: MatSnackBar,
//    private scrollDispatcher: ScrollDispatcher
  ) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactFormComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`, result);
      if(result && result.send==='ok') {
        this.contacts.create(result.data).subscribe((response) => {
          console.log(response)
          this.snackBar.open('Su consulta ha sido registrada. Pronto tendrá noticias!.', 'ok!', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          })
        })
      }
    })
  }

  openSolutionDialog(dialogTemplate:TemplateRef<any>) {
    console.log(dialogTemplate);
    this.dialog.open(dialogTemplate);
  }
}
