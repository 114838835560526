<app-blog-header #firstContainer id="firstContainer"></app-blog-header>

<div style="padding: 4vh 15vw;"
  fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start start" fxLayoutGap="1em"
  >
  <div fxFlex gdColumns="repeat(auto-fill, minmax(300px, 1fr))" gdColumns.xs="repeat(auto-fill, minmax(200px, 1fr))" gdColumns.sm="repeat(auto-fill, minmax(250px, 1fr))" gdGap="1em">
    <mat-card *ngFor="let article of entries"
      xfxFlex.xs="" xfxFlex.xs="" xfxFlex.sm="" xfxFlex.md="1 1 calc(50% - 1em)" xfxFlex.lg="1 1 calc(33% - 1em)" xfxFlex.xl="1 1 calc(25% - 1em)" class="article" fxLayout="column" fxLayoutAlign="start start"
      gdColumn="auto / span 1"
      >
      <a fxFlex style="width: 100%; height: 100%;" class="color-grey-800" [href]="'/blog/article/' + article.article"><img [src]="article.image" style="width: 100%; height: 100%;"></a>
      <div fxFlex fxFlex.xs="grow" fxLayout="column" fxLayoutAlign="start start">
        <div fxFlex>
          <h4 style="font-size: 0.8em;"><a class="color-blue-a700" style="text-decoration: underline;" href="/blog/authored/{{article.author_name}}" [innerText]="article.author_name"></a><em> escribió</em></h4>
          <h2><a class="color-grey-800" [innerText]="article.title" [href]="'/blog/article/' + article.article" style="text-decoration: none;"></a></h2>
          <h4 class="article-header-footer" style="font-size: 0.8em;"><span class="color-grey-a300" [innerText]="article.created|date"></span><em> en </em><a *ngFor="let tag of article.tags" class="color-blue-a700" style="text-decoration: underline; margin-right: 5px;" [innerText]="tag" href="/blog/tagged/{{tag}}"></a></h4>
        </div>
        <div fxFlex>
          <a class="color-grey-800" [innerText]="article.header" [href]="'/blog/article/' + article.article" style="text-decoration: none; font-weight: 400;"></a>
        </div>
      </div>
    </mat-card>
  </div>
  <mat-card fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="30%;" fxFlex.lg="25%;" fxFlex.xl="20%;">
    <app-blog-main-side-panel></app-blog-main-side-panel>
  </mat-card>
</div>
