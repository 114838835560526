<div id="firstContainer" style="padding: 10vh 15vw; background: url(/assets/img/background-solutions.png); background-size: cover; background-position: center;">
  <div fxLayout="row wrap" fxLayoutAlign="center center" id="part01">
    <div fxFlex="1 1 80%" class="color-white">
      <h1 style="font-weight: 800;">Soluciones</h1>
      <h3>Compartimos nuestra experiencia y pasión para ofrecerle soluciones que potencien su negocio</h3>
    </div>
    <button fxFlex="1 1 220px" mat-raised-button color="primary" class="stroked-blue-400" disableRipple="true" isRoundButton="true" (click)="openContactFormDialog();">Háblenos de su idea</button>
  </div>
</div>

<div fxLayout="row wrap" fxLayoutAlign="space-evenly center" style="padding: 5vh 15vw;">
  <mat-card fxFlex="1 1 200px" style="margin: 10px;">
    <img mat-card-image src="/assets/img/solution1.png" style="max-width: 100%; margin: 10px auto;">
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate01);">Saber Más</button>
    </mat-card-actions>
  </mat-card>
  <mat-card fxFlex="1 1 200px" style="margin: 10px;">
    <img mat-card-image src="/assets/img/solution2.png" style="max-width: 100%; margin: 10px auto;">
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate02);">Saber Más</button>
    </mat-card-actions>
  </mat-card>
  <mat-card fxFlex="1 1 200px" style="margin: 10px;">
    <img mat-card-image src="/assets/img/solution3.png" style="max-width: 100%; margin: 10px auto;">
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate03);">Saber Más</button>
    </mat-card-actions>
  </mat-card>
  <mat-card fxFlex="1 1 200px" style="margin: 10px;">
    <img mat-card-image src="/assets/img/solution4.png" style="max-width: 100%; margin: 10px auto;">
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate04);">Saber Más</button>
    </mat-card-actions>
  </mat-card>
  <mat-card fxFlex="1 1 200px" style="margin: 10px;">
    <img mat-card-image src="/assets/img/solution5.png" style="max-width: 100%; margin: 10px auto;">
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate05);">Saber Más</button>
    </mat-card-actions>
  </mat-card>
  <mat-card fxFlex="1 1 200px" style="margin: 10px;">
    <img mat-card-image src="/assets/img/solution6.png" style="max-width: 100%; margin: 10px auto;">
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate06);">Saber Más</button>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #solutionsTemplate01>
  <h1 mat-dialog-title>Inteus</h1>
  <div mat-dialog-content>La solución tecnológica para la gestión documental y los procesos de los sistemas de calidad, medio ambiente, seguridad y salud.</div>
  <div mat-dialog-actions>
    <a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a>
  </div>
</ng-template>

<ng-template #solutionsTemplate02>
  <h1 mat-dialog-title>Reforext</h1>
  <div mat-dialog-content>La aplicación móvil que permite el inventario y la gestión de los espacios forestales.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate03>
  <h1 mat-dialog-title>CRM Despachos</h1>
  <div mat-dialog-content>El software en la nube para la gestión de despachos profesionales.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate04>
  <h1 mat-dialog-title>Kronos App</h1>
  <div mat-dialog-content>La app de control horario que permite contabilizar y gestionar la jornada laboral de sus trabajadores y cumplir con la normativa legal.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate05>
  <h1 mat-dialog-title>App Forms</h1>
  <div mat-dialog-content>La app que te permite trabajar con formularios inteligentes y flujos de trabajo desde tu móvil.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate06>
  <h1 mat-dialog-title>GO</h1>
  <div mat-dialog-content>La aplicación web para la gestión y el seguimiento de obras y proyectos de construcción.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
    <a target="_blank" href="https://www.masfamilia.org/canal/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a>
  </div>
</ng-template>
