<div style="padding: 5vh 15vw;">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <img src="/assets/img/call-center.png" fxFlex.xs="1 1 100%" fxFlex.sm="1 1 70%" fxFlex="20%">
    <div fxFlex.xs="1 1 auto" fxFlex.sm="1 1 55%" fxFlex style="padding: 2vh 2vw;">
      <h1 class="color-grey-800" style="font-weight: 600;">Podemos ayudarle</h1>
      <h3 class="color-grey-800">Envíenos un breve resumen de su idea o coméntenos sus dudas si quieres saber más acerca de nosotros. Le responderemos en menos de 24 horas.</h3>
    </div>
    <div fxFlex style="text-align: center; margin: 2vw;">
      <button mat-raised-button color="primary" class="color-white" style="width: 30vw; min-width: 250px;" (click)="openDialog();">¿Hablamos de tu proyecto?</button>
    </div>
  </div>
</div>

<div style="padding: 5vh 15vw;">
  <img src="/assets/img/logo-blue.png">
  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="50%" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="row wrap" fxLayoutAlign="space-between start" fxLayoutAlign.xs="space-between start" style="margin: 1vh 0;">
      <div fxFlex style="margin-right: 25px;" class="themed-link"><i class="material-icons" style="float: left;">location_on</i><div style="float: left; margin-left: 5px;">Atocha, 92, 1ª<br/>28012 Madrid, España</div></div><br>
      <div fxFlex style="margin-right: 25px;" class="themed-link"><i class="material-icons" style="float: left;">call</i><div style="float: left; margin-left: 5px;">+34 91 861 62 14</div></div><br>
      <div fxFlex style="margin-right: 25px;" class="themed-link"><i class="material-icons" style="float: left;">mail_outline</i><div style="float: left; margin-left: 5px;"><a href="mailto:contacto@stsoftware.es" class="themed-link">contacto@stsoftware.es</a></div></div>
    </div>
    <div fxFlex="25%" fxFlex.xs="grow" fxLayout="column" fxLayout.xs="row wrap" fxLayoutAlign="space-between start" fxLayoutAlign.xs="space-between start" style="margin: 1vh 0;">
      <div fxFlex style="margin-right: 25px;"><a routerLink="" class="themed-link">Compañía</a></div>
      <div fxFlex style="margin-right: 25px;"><a href="services" class="themed-link">Servicios</a></div>
      <div fxFlex style="margin-right: 25px;"><a href="solutions" class="themed-link">Soluciones</a></div>
      <div fxFlex style="margin-right: 25px;"><a href="we" class="themed-link">Nosotros</a></div>
      <div fxFlex style="margin-right: 25px;"><a href="contact" class="themed-link">Contacto</a></div>
      <div fxFlex style="margin-right: 25px;"><a href="blog"  class="themed-link">Blog</a></div>
    </div>
    <div fxFlex="25%" fxFlex.xs="grow" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="space-between start"fxLayoutAlign.xs="space-between start">
      <div class=""><a href="https://linkedin.com/" class="themed-link"><i class="fa fa-3x fa-linkedin"></i></a></div>
      <div class=""><a href="https://twitter.com/" class="themed-link"><i class="fa fa-3x fa-twitter-square"></i></a></div>
      <div class=""><a href="https://youtube.com/" class="themed-link"><i class="fa fa-3x fa-youtube-square"></i></a></div>
    </div>
  </div>
</div>

<div style="padding: 3vh 15vw; background-color: #003358;">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <a fxFlex href="/legal-advise.pdf" class="color-white">Aviso legal</a>
    <a fxFlex href="/privacy-policy.pdf" class="color-white">Política de privacidad</a>
    <span fxFlex class="color-white" style="text-align: right;">Copyright © 2021 Sinergia tecnología y servicios software</span>
  </div>
</div>
