<swiper id="firstContainer"
  [autoplay]="{delay: 4000}"
  [loop]="true"
  [speed]="1000"
  [slidesPerView]="1"
  [spaceBetween]="0"
  [navigation]="false"
  [pagination]="{ clickable: true }"
  [scrollbar]="{ draggable: true }"
  [effect]="'fade'"
  style="position: relative; height: 100vh;">
  <ng-template swiperSlide>
    <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%; width: 100; position: relative; background: linear-gradient(0deg, rgba(30, 50, 70, 0.7), rgba(30, 50, 70, 0.7)), url(/assets/img/slider1.png); background-size: cover;">
      <div class="color-white white-text" fxFlex="1 1 85%" fxLayout="column" fxLayoutAlign="center center">
        <div style="margin: 0vh 5vw;">
          <h1 class="hide-on-med-and-down" fxHide.lt-lg>¡Bienvenido a <span style="font-weight: 600;">Sinergia</span>!</h1>
          <h1 class="show-on-small show-on-medium hide-on-large-only" fxHide.gt-md>¡Bienvenido!</h1>
          <h2 class="hide-on-small-only" fxHide.lt-lg>Desarrollamos <span style="white-space: nowrap; font-weight: 600;">soluciones software</span> que se adaptan a las necesidades de su empresa</h2>
          <h2 class="show-on-small hide-on-med-and-up" fxHide.gt-md>Desarrollamos <span style="font-weight: 600;">software</span> adaptado a sus necesidades</h2>
          <h2 class="truncate hide-on-med-and-down" fxHide.lt-lg>Si su negocio está cambiando, pero su software no, <span style="font-weight: 600;">podemos ayudarle</span></h2>
        </div>
      </div>
      <div fxFlex="1 1 15%">
        <button mat-raised-button color="primary" disableRipple="true" isRoundButton="true" style="margin: 0px auto;" (click)="openDialog();">¿Hablamos de su proyecto?</button>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div fxLayout="column" fxLayoutAlign="center center" class="valign-wrapper" style="height: 100%; width: 100; position: relative; background: linear-gradient(0deg, rgba(30, 50, 70, 0.7), rgba(30, 50, 70, 0.7)), url(/assets/img/slider2.png); background-size: cover;">
      <div class="color-white white-text" fxFlex="1 1 85%" fxLayout="column" fxLayoutAlign="center center">
        <div>
          <h1>Servicios</h1>
          <h2 class="hide-on-med-and-down" fxHide.lt-lg>Entendemos el servicio como una actitud, dar para facilitar el cumplimiento de un requisito, resolver una necesidad, o satisfacer una expectativa.</h2>
          <h2 class="show-on-small show-on-medium hide-on-large-only" fxHide.gt-md>Entendemos el servicio como una actitud: dar, resolver y cumplir</h2>
        </div>
      </div>
      <div fxFlex="1 1 15%">
        <button mat-raised-button color="primary" disableRipple="true" isRoundButton="true" style="margin: 0px auto;" (click)="openDialog();">¿Hablamos de su proyecto?</button>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div fxLayout="column" fxLayoutAlign="center center" class="valign-wrapper" style="height: 100%; width: 100; position: relative; background: linear-gradient(0deg, rgba(30, 50, 70, 0.7), rgba(30, 50, 70, 0.7)), url(/assets/img/slider3.png); background-size: cover;">
      <div class="color-white white-text" fxFlex="1 1 85%" fxLayout="column" fxLayoutAlign="center center">
        <div>
          <h1>Soluciones</h1>
          <h2 class="hide-on-med-and-down" fxHide.lt-lg>Desarrollamos e implantamos soluciones sencillas, eficientes y rentables que agilicen los procesos de su organización.</h2>
          <h2 class="show-on-small show-on-medium hide-on-large-only" fxHide.gt-md>Sencillez, eficiencia y rentabilidad para su negocio.</h2>
        </div>
      </div>
      <div fxFlex="1 1 15%">
        <button mat-raised-button color="primary" disableRipple="true" isRoundButton="true" style="margin: 0px auto;" (click)="openDialog();">¿Hablamos de su proyecto?</button>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div fxLayout="column" fxLayoutAlign="center center" class="valign-wrapper" style="height: 100%; width: 100; position: relative; background: linear-gradient(0deg, rgba(30, 50, 70, 0.7), rgba(30, 50, 70, 0.7)), url(/assets/img/slider4.png); background-size: cover;">
      <div class="color-white white-text" fxFlex="1 1 85%" fxLayout="column" fxLayoutAlign="center center">
        <div>
          <h1>Sobre nosotros</h1>
          <h2 class="hide-on-med-and-down" fxHide.lt-lg>Abordamos cada proyecto como si fuera el primero de muchos compromisos en los que la satisfacción del cliente a largo plazo es el eje de cada decisión que se toma.</h2>
          <h2 class="show-on-small show-on-medium hide-on-large-only" fxHide.gt-md>Su proyecto y su satisfacción es nuestro compromiso.</h2>
        </div>
      </div>
      <div fxFlex="1 1 15%">
        <button mat-raised-button color="primary" disableRipple="true" isRoundButton="true" style="margin: 0px auto;" (click)="openDialog();">¿Hablamos de su proyecto?</button>
      </div>
    </div>
  </ng-template>
</swiper>

<div style="margin: 5vh 15vw;">
  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <h1 fxFlex="60%" class="color-grey-800" style="font-weight: 800;">Servicios</h1>
    <a fxFlex="40%" mat-stroked-button color="primary" class="stroked-blue-400" disableRipple="true" isRoundButton="true" style="margin: 0px auto;" href="/services">Saber más</a>
    <h3 fxFlex="100%" class="color-grey-600">Aportamos compromiso y atención personalizada en cualquier proyecto TI y nos adaptaremos a sus necesidades</h3>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    <div fxFlex="1 1 300px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/programming.png" style="max-width: 250px; margin: 10px auto;">
      <h3 class="color-grey-600" style="text-align: center; font-weight: 500;">Desarrollo de software</h3>
      <mat-card-content>
        <h4 class="color-grey-600">Desarrollamos software a medida y aplicaciones web que ayudan a crecer a su organización.</h4>
      </mat-card-content>
      <mat-card-actions>
        <a mat-stroked-button color="primary" href="services#part01">Saber Más</a>
      </mat-card-actions>
    </div>
    <div fxFlex="1 1 300px" style="margin: 10px; background-color: ;">
      <img mat-card-image src="/assets/img/consulting.png" style="max-width: 250px; margin: 10px auto;">
      <h3 class="color-grey-600" style="text-align: center; font-weight: 500;">Consultoría tecnológica</h3>
      <mat-card-content>
        <h4 class="color-grey-600">Asesoramos en la transformación digital y tecnológica de su empresa para que sea más eficiente y competitiva.</h4>
      </mat-card-content>
      <mat-card-actions>
        <a mat-stroked-button color="primary" href="services#part02">Saber Más</a>
      </mat-card-actions>
    </div>
    <div fxFlex="1 1 300px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/monitoring.png" style="max-width: 250px; margin: 10px auto;">
      <h3 class="color-grey-600" style="text-align: center; font-weight: 500;">Operaciones y mantenimiento</h3>
      <mat-card-content>
        <h4 class="color-grey-600">Prestamos soporte técnico para mantener  sus sistemas informáticos operativos y actualizados.</h4>
      </mat-card-content>
      <mat-card-actions>
        <a mat-stroked-button color="primary" href="services#part03">Saber Más</a>
      </mat-card-actions>
    </div>
  </div>
</div>

<div style="padding: 5vh 15vw; background: linear-gradient(0deg, rgba(30, 50, 70, 0.7), rgba(30, 50, 70, 0.7)), url(/assets/img/background-solutions.png); background-size: cover;">
  <h1 class="color-white" style="font-weight: 800;">Soluciones</h1>
  <h3 class="color-white">Compartimos nuestra experiencia y pasión para ofrecerle soluciones que potencien su negocio</h3>
  <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    <mat-card fxFlex="1 1 200px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/solution1.png" style="max-width: 100%; margin: 10px auto;">
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate01);">Saber Más</button>
      </mat-card-actions>
    </mat-card>
    <mat-card fxFlex="1 1 200px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/solution2.png" style="max-width: 100%; margin: 10px auto;">
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate02);">Saber Más</button>
      </mat-card-actions>
    </mat-card>
    <mat-card fxFlex="1 1 200px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/solution3.png" style="max-width: 100%; margin: 10px auto;">
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate03);">Saber Más</button>
      </mat-card-actions>
    </mat-card>
    <mat-card fxFlex="1 1 200px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/solution4.png" style="max-width: 100%; margin: 10px auto;">
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate04);">Saber Más</button>
      </mat-card-actions>
    </mat-card>
    <mat-card fxFlex="1 1 200px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/solution5.png" style="max-width: 100%; margin: 10px auto;">
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate05);">Saber Más</button>
      </mat-card-actions>
    </mat-card>
    <mat-card fxFlex="1 1 200px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/solution6.png" style="max-width: 100%; margin: 10px auto;">
      <mat-card-actions>
        <button mat-stroked-button color="primary" (click)="openSolutionDialog(solutionsTemplate06);">Saber Más</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #solutionsTemplate01>
  <h1 mat-dialog-title>Inteus</h1>
  <div mat-dialog-content>La solución tecnológica para la gestión documental y los procesos de los sistemas de calidad, medio ambiente, seguridad y salud.</div>
  <div mat-dialog-actions>
    <a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a>
  </div>
</ng-template>

<ng-template #solutionsTemplate02>
  <h1 mat-dialog-title>Reforext</h1>
  <div mat-dialog-content>La aplicación móvil que permite el inventario y la gestión de los espacios forestales.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate03>
  <h1 mat-dialog-title>CRM Despachos</h1>
  <div mat-dialog-content>El software en la nube para la gestión de despachos profesionales.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate04>
  <h1 mat-dialog-title>Kronos App</h1>
  <div mat-dialog-content>La app de control horario que permite contabilizar y gestionar la jornada laboral de sus trabajadores y cumplir con la normativa legal.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate05>
  <h1 mat-dialog-title>App Forms</h1>
  <div mat-dialog-content>La app que te permite trabajar con formularios inteligentes y flujos de trabajo desde tu móvil.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<ng-template #solutionsTemplate06>
  <h1 mat-dialog-title>GO</h1>
  <div mat-dialog-content>La aplicación web para la gestión y el seguimiento de obras y proyectos de construcción.</div>
  <div mat-dialog-actions>
    <!-- a target="_blank" href="https://inteus.es/" mat-basic-button mat-dialog-close color="primary"><i class="material-icons" style="float: right; margin-left: 15px;">launch</i>Ir</a -->
  </div>
</ng-template>

<div style="padding: 5vh 15vw; background: #003358;">
  <h1 class="color-white" style="font-weight: 800;">Sobre nosotros</h1>
  <h3 class="color-white"></h3>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <img src="/assets/img/organization.png" fxFlex="1 1 300px">
    <div fxFlex style="padding: 5vh 10vw;">
      <h3 class=" color-white white-text">Nos gusta lo que hacemos y nos apasiona nuestro trabajo.</h3>
      <h3 class=" color-white white-text">Compartimos nuestra experiencia y pasión para crear soluciones que potencien su negocio.<br/>Entendemos la tecnología no como un fin, sino como un medio para ofrecer soluciones competitivas a nuestros clientes.</h3>
    </div>
    <div fxFlex="grow" style="text-align: center;">
      <a mat-raised-button color="primary" class="color-white" href="we" style="width: 20vw; min-width: 150px;">¿Saber más?</a>
    </div>
  </div>
</div>

<div style="margin: 5vh 15vw;">
  <h1 class="color-grey-800" style="font-weight: 800;">¿Por qué Sinergia?</h1>
  <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    <div fxFlex="1 1 250px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/revenue.png" style="max-width: 250px; margin: 10px auto;">
      <h3 class="color-grey-800" style="text-align: center; font-weight: 500;">Rentabilidad</h3>
      <mat-card-content>
        <h4 class="color-grey-800">Ofrecemos soluciones que  no  sólo  le ahorrarán dinero y mejorarán sus procesos, sino que  le  ayudarán a  cumplir sus  objetivos empresariales.</h4>
      </mat-card-content>
    </div>
    <div fxFlex="1 1 250px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/skills.png" style="max-width: 250px; margin: 10px auto;">
      <h3 class="color-grey-800" style="text-align: center; font-weight: 500;">Experiencia</h3>
      <mat-card-content>
        <h4 class="color-grey-800">Cuando trabaja con Sinergia fortalece su proyecto con los mejores talentos. Todos nuestros profesionales tienen más de 15 años de experiencia.</h4>
      </mat-card-content>
    </div>
    <div fxFlex="1 1 250px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/report.png" style="max-width: 250px; margin: 10px auto;">
      <h3 class="color-grey-800" style="text-align: center; font-weight: 500;">Asesoramiento</h3>
      <mat-card-content>
        <h4 class="color-grey-800">Mantenemos una orientación y una visión constantes, compartiendo nuestros conocimientos para que tome la decisión correcta en todo momento.</h4>
      </mat-card-content>
    </div>
    <div fxFlex="1 1 250px" style="margin: 10px;">
      <img mat-card-image src="/assets/img/piggy-bank.png" style="max-width: 250px; margin: 10px auto;">
      <h3 class="color-grey-800" style="text-align: center; font-weight: 500;">Reducción de costes</h3>
      <mat-card-content>
        <h4 class="color-grey-800">Nuestro lema: sólo paga por lo que necesita. Reducimos los costes de desarrollo optimizando el rendimiento y los recursos.</h4>
      </mat-card-content>
    </div>
  </div>
</div>

<div style="padding: 5vh 15vw; background: url(/assets/img/hands.png); background-position: right;">
  <h1 class="color-white" style="font-weight: 800;">Nuestros clientes</h1>
  <h3 class=" color-white white-text">Creamos una asociación a largo plazo con los clientes construida sobre la base de la confianza, la transparencia y la flexibilidad.</h3>
  <h3 class=" color-white white-text">Estamos encantados de trabajar con grandes organizaciones de múltiples sectores, pero también orgullosos de apoyar a pequeñas y medianas empresas.</h3>
  <h3 class=" color-white white-text">Lo que todos tienen en común, haber confiado en Sinergia para satisfacer sus expectativas.</h3>
</div>

<div style="padding: 5vh 15vw;" fxLayout="row wrap" fxLayoutAlign="center center">
  <img fxFlex="80%" fxFlex.xs="100%" fxHide fxShow.sm fxShow.xs src="/assets/img/Logos-2-columnas-1.png"/>
  <img fxFlex="80%" fxFlex.xs="100%" fxHide fxShow.sm fxShow.xs src="/assets/img/Logos-2-columnas-2.png"/>
  <img fxFlex="100%" fxHide fxShow.md fxShow.lg fxShow.xl src="/assets/img/Logos-4-columnas.png"/>
  <!--
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente02.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente01.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente03.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente04.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente06.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente05.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente07.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: 0 auto; max-width: 180px;" src="/assets/img/logo-cliente08.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: auto; max-width: 180px;" src="/assets/img/logo-cliente10.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: auto; max-width: 180px;" src="/assets/img/logo-cliente09.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: auto; max-width: 180px;" src="/assets/img/logo-cliente11.png"></div>
  <div fxFlex="1 1 195px;"><img style="margin: auto; max-width: 180px;" src="/assets/img/logo-cliente12.png"></div>
  -->
</div>
