import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { ContactService } from '../contact.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public dialog: MatDialog,
    private contacts: ContactService,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {

  }
  openDialog() {
    const dialogRef = this.dialog.open(ContactFormComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`, result);
      if(result && result.send==='ok') {
        this.contacts.create(result.data).subscribe((response) => {
          console.log(response)
          this.snackBar.open('Su consulta ha sido registrada. Pronto tendrá noticias!.', 'ok!', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          })
        })
      }
    })
  }
}
