import { Component, Inject, OnInit, Optional } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface UsersData {
  firstName: string;
  surname: string;
  company: string;
  email: string;
  message: string;
  cause: string;
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent implements OnInit {
  public submitted = false;

  public formGroup: FormGroup = null;
  public contactForm: UsersData = {
    firstName: null, // "Jesús",
    surname: null, //"Alonso",
    company: null, //"Sinergia",
    email: null, //"jesus.alonso@inteus.es",
    message: null, //"Esta es la canción, del pirata, poesía",
    cause: null
  }

  private contactFormCompletenessValidator: ValidatorFn = (control: AbstractControl) : ValidationErrors | null => {
    const firstName = control.get('firstName');
    const surname = control.get('surname');
    const company = control.get('company');
    const email = control.get('email');
    const message = control.get('message');
    const cause = control.get('cause');

    return  firstName &&
            company &&
            email &&
            message &&
            ! firstName.errors &&
            ! message.errors &&
            ! email.errors
            ? { isComplete: true } : { isComplete: false };
  }

  constructor(
    public dialogRef: MatDialogRef<ContactFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      firstName: new FormControl(this.contactForm.firstName, [Validators.required, Validators.minLength(4), Validators.maxLength(32)]),
      surname: new FormControl(this.contactForm.surname),
      company: new FormControl(this.contactForm.company),
      email: new FormControl(this.contactForm.email, [Validators.required, Validators.email]),
      message: new FormControl(this.contactForm.message, [Validators.required, Validators.minLength(20)]),
      cause: new FormControl(this.contactForm.cause)
    }, { validators: this.contactFormCompletenessValidator })
  }

  get firstName() { return this.formGroup.get('firstName') }
  get surname() { return this.formGroup.get('surname') }
  get company() { return this.formGroup.get('company') }
  get email() { return this.formGroup.get('email') }
  get message() { return this.formGroup.get('message') }
  get cause() { return this.formGroup.get('cause') }

  onSubmit() {

    this.dialogRef.close({data: this.contactForm, send: 'ok'});
    /*
    this.submitted = true;

    this.contacts.create(this.contactForm).subscribe((response) => {
      this.
    })*/
  }
}
