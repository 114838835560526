<div id="firstContainer" style="padding: 10vh 15vw; background: url(/assets/img/services-background.png); background-size: cover; background-position: center;">
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <div fxFlex="1 1 80%" class="color-white">
      <h1 style="font-weight: 800;">Contacta con nosotros</h1>
      <h3>Gracias por visitar nuestro pequeño rincón en internet.</h3>
      <h3>Si quieres contactar con nuestro equipo puedes rellenar el siguiente formulario o enviarnos un email a <a href="mailto:contacto@inteus.es" class="white-themed-link" style="text-decoration: underline;"><span class="fa fa-envelope"></span> dirección de contacto</a>. Estaremos encantados de escucharte.</h3>
    </div>
  </div>
</div>

<div style="padding: 5vh 15vw;" fxLayout="column" fxLayoutGap="5vh" fxLayoutAlign="center center">
  <h1 fxFlex>Rellene este formulario</h1>
  <div fxFlex>
    <form [formGroup]="formGroup" #formDir="ngForm" (ngSubmit)="onSubmit();">
      <div fxLayout="column" fxLayoutGap="1%" [hidden]="formDir.submitted">
        <div fxFlex>
          <mat-form-field appearance="legacy" style="width: 50vw;">
            <mat-label>Nombre de pila</mat-label>
            <mat-hint>Min. 4 car. / Max. 32 car.</mat-hint>
            <input type="text" matInput [formControl]="firstName" placeholder="Ej. Juan" required>
            <!--mat-error *ngIf="firstName.hasError('required')">Este campos es <strong>obligatorio</strong> y su contenido <strong>válido</strong></mat-error-->
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field appearance="legacy" style="width: 50vw;">
            <mat-label>Apellido(s)</mat-label>
            <input type="text" matInput [formControl]="surname">
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field appearance="legacy" style="width: 50vw;">
            <mat-label>Empresa a la que representa</mat-label>
            <input type="text" matInput [formControl]="company">
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field appearance="legacy" style="width: 50vw;">
            <mat-label>Email</mat-label>
            <mat-hint>El email debe ser válido</mat-hint>
            <input type="email" matInput [formControl]="email" required>
            <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">Introduca una dirección de email válida</mat-error>
            <!--mat-error *ngIf="email.hasError('required')">Este campos es <strong>obligatorio</strong></mat-error-->
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field appearance="legacy" style="width: 50vw;">
            <mat-label>Servicio o solución</mat-label>
            <mat-select [formControl]="cause" class="color-grey-800" >
              <mat-option value="" disabled selected>¿Sobre qué servicio o solución realiza su consulta?</mat-option>
              <mat-option value="Desarrollo de software">Desarrollo de software</mat-option>
              <mat-option value="Consultoría Tecnológica">Consultoría Tecnológica</mat-option>
              <mat-option value="Mantenimiento y Soporte">Mantenimiento y Soporte</mat-option>
              <mat-option value="Inteus">Inteus</mat-option>
              <mat-option value="E-reports">E-reports</mat-option>
              <mat-option value="Reforext">Reforext</mat-option>
              <mat-option value="CRM Legal">CRM Legal</mat-option>
              <mat-option value="Audit app">Audit app</mat-option>
              <mat-option value="Kronos app">Kronos app</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field appearance="legacy" style="width: 50vw;">
            <mat-label>Mensaje</mat-label>
            <mat-hint>Min. 20 car.</mat-hint>
            <textarea matInput [formControl]="message" required></textarea>
            <!--mat-error *ngIf="message.hasError('required')">Este campos es <strong>obligatorio</strong></mat-error-->
          </mat-form-field>
        </div>
        <div fxFlex style="width: 50vw;">
          <h3 class="color-red-600" *ngIf="!formGroup.errors?.isComplete">* Es necesario completar los campos obligatorios antes de remitir el formulario.</h3>
        </div>
        <div fxFlex style="width: 50vw;">
          <button type="submit" mat-raised-button color="primary" disableRipple="true" isRoundButton="true" [disabled]="!formGroup.errors?.isComplete" style="width: 100%;">Contactar</button>
        </div>
      </div>
    </form>
  </div>
</div>
