import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule, InMemoryWebApiModule } from 'angular-in-memory-web-api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { WeComponent } from './we/we.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule} from '@angular/material/dialog';
import { MatInputModule} from '@angular/material/input';
import { MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SwiperModule } from 'swiper/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContactFormComponent } from './contact-form/contact-form.component';
import { ContactService } from './contact.service';
// import { InMemContactService } from './in-mem-contact.service';
import { BlogComponent } from './blog/blog.component';
import { BlogHeaderComponent } from './blog-header/blog-header.component';
import { BlogEntryComponent } from './blog-entry/blog-entry.component';
import { BlogService } from './blog.service';
import { InMemBlogService } from './in-mem-blog.service';
import { ArticleComponent } from './article/article.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { BlogMainSidePanelComponent } from './blog-main-side-panel/blog-main-side-panel.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ServicesComponent,
    ContactComponent,
    WeComponent,
    ContactFormComponent,
    BlogComponent,
    BlogHeaderComponent,
    BlogEntryComponent,
    ArticleComponent,
    SolutionsComponent,
    BlogMainSidePanelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCardModule,
    MatChipsModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    FlexLayoutModule,
    HttpClientModule,
    // environment.production ?
// con solo un inmemservice es suficiente que contenga las 2 coleciones de datos
//    HttpClientInMemoryWebApiModule.forRoot(InMemContactService, { delay: 100, passThruUnknownUrl: true }), // : []
    HttpClientInMemoryWebApiModule.forRoot(InMemBlogService, {apiBase: 'api/', passThruUnknownUrl: true })
  ],
  providers: [ContactService, BlogService],
  bootstrap: [AppComponent]
})

export class AppModule {}
