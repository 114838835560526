import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticleComponent } from './article/article.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { WeComponent } from './we/we.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'services',
    pathMatch: 'full',
    component: ServicesComponent,
  },
  {
    path: 'solutions',
    pathMatch: 'full',
    component: SolutionsComponent,
  },
  {
    path: 'we',
    pathMatch: 'full',
    component: WeComponent
  },
  {
    path: 'contact',
    pathMatch: 'full',
    component: ContactComponent
  },
  {
    path: 'blog/authored/:author',
    component: BlogComponent
  },
  {
    path: 'blog/search',
    component: BlogComponent
  },
  {
    path: 'blog/tagged/:tag',
    component: BlogComponent
  },
  {
    path: 'blog',
    pathMatch: 'full',
    component: BlogComponent
  },
  {
    path: 'blog/article/:article',
    component: ArticleComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
