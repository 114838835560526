import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BlogEntry } from './blog-entry';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private blogUrl = 'api/blog';  // URL to web api

  constructor(private http: HttpClient) { }

  search(term: string): Observable<BlogEntry[]> {
    return this
      .http
        .get(`${this.blogUrl}/?author=${term}`)
        .pipe(
          tap((response: any) => response.json().data as BlogEntry[])
        )
  }


  create(contactForm: Object): Observable<BlogEntry> {
    return this.http
      .post(this.blogUrl, contactForm, { headers: this.headers })
      .pipe(
        tap((res:any) => res.data as BlogEntry)
      )
  }

  getBlogEntries(): Observable<BlogEntry[]> {
    return this
      .http
      .get(this.blogUrl, { headers: this.headers, responseType: 'json' })
      .pipe(
        tap((res:any) => res.data as BlogEntry[])
      )
  }

  getBlogEntryByArticle(article: string): Observable<BlogEntry[]> {
    return this
      .http
      .get(this.blogUrl + '/?article=' + article)
      .pipe(
        tap((res:any) => res.data as BlogEntry[])
      )
  }

  getBlogEntriesByAuthor(author: string): Observable<BlogEntry[]> {
    return this
      .http
      .get(this.blogUrl + '/?author_name=' + author)
      .pipe(
        tap((res:any) => res.data as BlogEntry[])
      )
  }

  getBlogEntriesByTag(tag: string): Observable<BlogEntry[]> {
    return this
      .http
      .get(this.blogUrl + '/?tagsStr='+tag)
      .pipe(
        tap((res:any) => {console.log(res); return res.data as BlogEntry[]})
      )
  }

  getBlogEntriesByQuery(q: string): Observable<BlogEntry[]> {
    console.log(this.blogUrl + '/?title=' + q.split(' ').join('&?title='))
    return this
      .http
      .get(this.blogUrl + '/?content=' + q.split(' ').join('&content='))
//      .get(this.blogUrl + '/?content=' + q/* + '/?author_name=' + q*/)
      .pipe(
        tap((res:any) => {console.log(res); return res.data as BlogEntry[]})
      )
  }

}
