import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

class Contact {
  firstName: string;
  surname: string;
  company: string;
  message: string;
  email: string;
  cause: string;
}
@Injectable({
  providedIn: 'root'
})

export class ContactService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private contactsUrl = 'api/contacts';  // URL to web api

  constructor(private http: HttpClient) { }

  search(term: string): Observable<Contact[]> {
    return this
      .http
        .get(`api/contacts/?name=${term}`)
        .pipe(
          tap((response: any) => response.data as Contact[])
        )
  }


  create(contactForm: Object): Observable<Contact> {
    return this.http
      .post(this.contactsUrl, contactForm, { headers: this.headers }).pipe(
        tap((res:any) => res.data as Contact)
      )
  }
}
/*

  private headers = new Headers({ 'Content-Type': 'application/json' });
  private heroesUrl = 'api/heroes';  // URL to web api

  constructor(private http: Http) {
  }

  getHeroes(): Promise<Hero[]> {
    return this.http.get(this.heroesUrl)
        .toPromise()
        .then(response => response.json().data as Hero[])
        .catch(this.handleError);
  }


  getHero(id: number): Promise<Hero> {
    const url = `${this.heroesUrl}/${id}`;
    return this.http.get(url)
        .toPromise()
        .then(response => response.json().data as Hero)
        .catch(this.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.heroesUrl}/${id}`;
    return this.http.delete(url, { headers: this.headers })
        .toPromise()
        .then(() => null)
        .catch(this.handleError);
  }

  create(name: string): Promise<Hero> {
    return this.http
        .post(this.heroesUrl, JSON.stringify({ name: name }), { headers: this.headers })
        .toPromise()
        .then(res => res.json().data as Hero)
        .catch(this.handleError);
  }

  update(hero: Hero): Promise<Hero> {
    const url = `${this.heroesUrl}/${hero.id}`;
    return this.http
        .put(url, JSON.stringify(hero), { headers: this.headers })
        .toPromise()
        .then(() => hero)
        .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
  */
