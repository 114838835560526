<div style="padding: 10vh 15vw; background: linear-gradient(0deg, rgba(30, 30, 30, 0.7), rgba(30, 30, 30, 0.7)), url(/assets/img/blog-header-background.jpg); background-size: cover; background-position: center;">
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <div fxFlex="1 1 80%" class="color-white">
      <h1 style="font-weight: 800;">Blog de Sinergia</h1>
      <h3>Gracias por visitar nuestro pequeño rincón en internet.</h3>
      <h3>Aquí encontrarás retales de nuestro saber hacer y nuestras experiencias.</h3>
    </div>
  </div>
</div>


