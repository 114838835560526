<div id="firstContainer" style="padding: 10vh 15vw; background: linear-gradient(0deg, rgba(30, 30, 30, 0.7), rgba(30, 30, 30, 0.7)), url(assets/img/we-background.png); background-size: cover; background-position: center;">
  <div fxLayout="row wrap" fxLayoutAlign="center center" id="part01">
    <div fxFlex="1 1 80%" class="color-white">
      <h1 style="font-weight: 800;">Sobre nosotros</h1>
      <h3>Compartimos nuestra experiencia y pasión para crear soluciones que potencien su negocio.</h3>
      <h3>Entendemos la tecnología no como un fin, sino como un medio para ofrecer soluciones competitivas a nuestros clientes.</h3>
    </div>
  </div>
</div>

<div style="padding: 5vh 15vw;" class="color-grey-800">
  <h3>Somos una compañía de servicios de tecnologías de la información global que diseña y construye aplicaciones web, sistemas en la nube , aplicaciones de escritorio y aplicaciones móviles inteligentes que mejoran la agilidad y la eficiencia empresarial.</h3>
  <h3>Cuando confía en nosotros ponemos a su disposición todos los conocimientos adquiridos por los miembros de nuestro equipo para proponerle soluciones que hemos implementado con éxito en empresas de diversos sectores y tamaños.</h3>
</div>

<div style="padding: 5vh 15vw;" class="color-grey-800">
  <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" fxLayoutGap="2vw">
    <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="2vw">
      <div fxFlex>
        <img src="/assets/img/we-statistics01.png" style="max-width: 50px; max-height: 50px;"/>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <h6 fxFlex style="font-size: 1.5em;">25+</h6>
        <p fxFlex>años de experiencia</p>
      </div>
    </div>
    <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="2vw">
      <div fxFlex>
        <img src="/assets/img/we-statistics02.png" style="max-width: 50px; max-height: 50px;"/>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <h6 fxFlex style="font-size: 1.5em;">80K +</h6>
        <p fxFlex>líneas de código</p>
      </div>
    </div>
    <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="2vw">
      <div fxFlex>
        <img src="/assets/img/we-statistics03.png" style="max-width: 50px; max-height: 50px;"/>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <h6 fxFlex style="font-size: 1.5em;">98%</h6>
        <p fxFlex>clientes satisfechos</p>
      </div>
    </div>
    <div fxFlex="1 1" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="2vw">
      <div fxFlex>
        <img src="/assets/img/we-statistics04.png" style="max-width: 50px; max-height: 50px;"/>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <h6 fxFlex style="font-size: 1.5em;">10.800</h6>
        <p fxFlex>tazas de café</p>
      </div>
    </div>
  </div>
</div>

<div style="padding: 5vh 15vw;" class="color-grey-800">
  <h3 style="font-weight: 500;">Nuestros valores</h3>
  <h3>Sinergia es un término de origen griego, "synergía", que significa “cooperación". El concepto es utilizado para nombrar a la acción de dos o más causas que generan un efecto superior al que se conseguiría con la suma de los efectos individuales.</h3>
  <h3>El mismo término define nuestra de razón de ser: observar en todo momento una conducta de colaboración permanente para con nuestros clientes, ofreciéndoles respuestas oportunas y de calidad.</h3>
  <h3>Dicha colaboración permanente se sustenta sobre los términos que constituyen nuestros valores.</h3>
</div>

<div style="padding: 5vh 15vw;" class="color-white bg-grey-800" fxLayout="row wrap">
  <div fxFlex fxFlex.xs="auto" style="margin: 0vh 5vw 0vh 0vw;">
    <h3 style="font-weight: 500;">Gratitud</h3>
    <h3>Somos agradecidos con quienes trabajan con nosotros y con aquellos para quienes trabajamos. Establecemos relaciones que marcan una diferencia positiva en la vida de todos.</h3>
    <h3 style="font-weight: 500;">Entusiasmo</h3>
    <h3>Nos gusta lo que hacemos y nos apasiona nuestro trabajo.</h3>
    <h3 style="font-weight: 500;">Esfuerzo</h3>
    <h3>Nos esforzamos por dar lo máximo para satisfacer las necesidades de los clientes más allá de sus expectativas.</h3>
  </div>
  <div fxFlex fxFlex.xs="grow" style="margin: 0vh 0vw 0vh 0vw;">
    <h3 style="font-weight: 500;">Creatividad</h3>
    <h3>Fomentamos la creatividad y la innovación entre nuestra gente.</h3>
    <h3 style="font-weight: 500;">Responsabilidad</h3>
    <h3>No somos conformistas y aceptamos los desafíos realizando un esfuerzo adicional. Asumimos los riesgos de manera responsable.</h3>
    <h3 style="font-weight: 500;">Integridad</h3>
    <h3>Actuamos de forma correcta e intachable, procediendo con rectitud y honradez en la conducta y en el comportamiento.</h3>
  </div>
</div>

<div style="padding: 5vh 15vw;" class="color-grey-800">
  <h2>Pilares fundamentales</h2>
  <div fxLayout="row wrap" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" fxLayoutAlign="start center" fxLayoutGap="2vw" style="margin-top: 4vh;">
    <img fxFlex.xs="75%" fxFlex.sm="75%" fxFlex="350px" src="/assets/img/red.png"/>
    <div fxFlex.xs="grow" fxFlex.sm="grow" fxFlex>
      <h3 style="font-weight: 500;">Colaboración</h3>
      <h3>Queremos ser su socio tecnológico y establecer un modelo de relación basado en criterios de permanencia, continuidad e implicación, conceptos que consideramos básicos para la aportación de valor.</h3>
      <h3>Nuestro equipo aborda cada proyecto como si fuera el primero de muchos compromisos en los que la satisfacción del cliente a largo plazo es el núcleo de cada una de las decisiones que se toman.</h3>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" fxLayoutAlign="start center" fxLayoutGap="2vw" style="margin-top: 4vh;">
    <img fxFlex.xs="75%" fxFlex.sm="75%" fxFlex="350px" src="/assets/img/entrega.png" fxHide="true" fxHide.xs="false" fxHide.sm="false"/>
    <div fxFlex.xs="grow" fxFlex.sm="grow" fxFlex>
      <h3 style="font-weight: 500;">Compromiso</h3>
      <h3>Entendemos el servicio como una actitud, dar para facilitar el cumplimiento de un proceso, resolver una necesidad, o satisfacer una expectativa.</h3>
      <h3>Somos honestos con nuestros clientes y transparentes con los requisitos, costes y plazos de los proyectos que acometemos juntos</h3>
      <h3>Cuando un proyecto se entrega con éxito, compartimos ese éxito y nos enorgullecemos de nuestro trabajo, pero si hay problemas o malentendidos, trabajamos incansablemente para resolverlo y ver las cosas desde su perspectiva</h3>
    </div>
    <img fxFlex.xs="75%" fxFlex.sm="75%" fxFlex="350px" src="/assets/img/entrega.png" fxHide="false" fxHide.xs="true" fxHide.sm="true"/>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center" fxLayoutAlign="start center" fxLayoutGap="2vw" style="margin-top: 4vh;">
    <img fxFlex.xs="75%" fxFlex.sm="75%" fxFlex="350px" src="/assets/img/operacion.png"/>
    <div fxFlex.xs="grow" fxFlex.sm="grow" fxFlex>
      <h3 style="font-weight: 500;">Innovación</h3>
      <h3>Ayudamos a las organizaciones a optimizar sus sistemas, aportando soluciones innovadoras para la gestión de los procesos y las personas, facilitando su aplicación, haciéndolos eficaces, ágiles y medibles.</h3>
      <h3>Estudiamos continuamente las últimas tendencias y nos adaptamos a las nuevas soluciones usando siempre la tecnología más actualizada disponible.</h3>
    </div>
  </div>
</div>
