// import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Observable } from 'rxjs';
import articles from '../assets/articles/articles.json';
import contacts from '../assets/contacts.json';
import { BlogEntry } from './blog-entry';

@Injectable({
  providedIn: 'root'
})

export class InMemBlogService implements InMemoryDbService {
  /*
  private blog = [
    {
      "id": 1,
      "author_name": "José Antonio Aznar Baeza",
      "author_role": "Product Manager ",
      "author_avatar": "/assets/avatar/jose-antonio-aznar.png",
      "title": "¿Cómo mejorar tu sistema de gestión de calidad? ¡Utiliza el software adecuado!",
      "created": new Date(2021, 0, 8, 10, 0, 0),
      "modified": new Date(2021, 0, 8, 10, 0, 0),
      "header": "El aumento de las exigencias que deben cumplir un sistema de gestión de calidad, unido al hecho de encontrarnos en un entorno cada vez más competitivo, está motivando a las organizaciones a apostar por la informatización de los sistemas de gestión de calidad como forma de optimizar el rendimiento. Pero… ¿Cómo hacerlo? Esa es la gran pregunta a la que suelen enfrentarse los responsables de calidad de las organizaciones.",
      "image": "/assets/articles/como-mejorar-tu-sistema-de-gestion-de-calidad-utiliza-el-software-adecuado.jpg",
      "comments": 5,
      "likes": 24,
      "dislikes": 0,
      "tags": ["Calidad", "Software", "Mejorar"],
      "article": "como-mejorar-tu-sistema-de-gestion-de-calidad-utiliza-el-software-adecuado",
      "content": ""
    },
    {
      "id": 2,
      "author_name": "José A. Aznar Baeza",
      "author_role": "Product Manager ",
      "author_avatar": "/assets/avatar/jose-antonio-aznar.png",
      "title": "¿Qué motivos impulsan a las empresas a automatizar sus Sistemas de Gestión ISO?",
      "created": new Date(2021, 0, 22, 10, 0, 0),
      "modified": new Date(2021, 0, 22, 10, 0, 0),
      "header": "Alcanzar la satisfacción de los clientes es uno de los objetivos primordiales a conseguir por parte de cualquier organización. Para lograrlo es preciso mejorar la eficiencia, siendo una de las formas de lograrlo la mejora de los procesos de gestión diaria.",
      "image": "/assets/articles/que-motivos-impulsan-a-las-empresas-a-automatizar-sus-sistemas-de-gestion-iso.jpg",
      "comments": 5,
      "likes": 24,
      "dislikes": 0,
      "tags": ["Calidad", "Software", "Ranking"],
      "article": "que-motivos-impulsan-a-las-empresas-a-automatizar-sus-sistemas-de-gestion-iso",
      "content": ""
    },
    {
      "id": 3,
      "author_name": "José A. Aznar Baeza",
      "author_role": "Product Manager ",
      "author_avatar": "/assets/avatar/jose-antonio-aznar.png",
      "title": "¿Qué puede hacer por ti un gestor documental en la Gestion ISO?",
      "created": new Date(2021, 1, 14, 10, 0, 0),
      "modified": new Date(2021, 1, 14, 10, 0, 0),
      "header": "Uno de los aspectos contemplados en las últimas revisiones de algunas normas ISO como la ISO 9001 y la ISO 14001 es la gestión de la información documentada. Este requisito establece que las organizaciones deben documentar debidamente la información de su Sistema de Gestión ISO.",
      "image": "/assets/articles/que-puede-hacer-por-ti-un-gestor-documental-en-la-gestion-iso.jpg",
      "comments": 5,
      "likes": 24,
      "dislikes": 0,
      "tags": ["Gestión documental", "Calidad", "Software", "Mejorar"],
      "article": "que-puede-hacer-por-ti-un-gestor-documental-en-la-gestion-iso",
      "content": ""
    },
    {
      "id": 4,
      "author_name": "José Antonio Aznar Baeza",
      "author_role": "Product Manager ",
      "author_avatar": "/assets/avatar/jose-antonio-aznar.png",
      "title": "10 formas en las que un Software de Gestión puede mejorar tu negocio",
      "created": new Date(2021, 2, 11, 10, 0, 0),
      "modified": new Date(2021, 2, 11, 10, 0, 0),
      "header": "Un software de gestión puede mejorar su negocio facilitando la implementación de otros sistemas de gestión relacionados, especialmente los dedicados a la planificación de recursos empresariales, obteniendo así una visión general completa de todas las actividades de la organización.",
      "image": "/assets/articles/10-formas-en-las-que-un-software-de-gestion-puede-mejorar-tu-negocio.jpg",
      "comments": 5,
      "likes": 24,
      "dislikes": 0,
      "tags": ["Gestión documental", "Mejorar", "Ranking"],
      "article": "10-formas-en-las-que-un-software-de-gestion-puede-mejorar-tu-negocio",
      "content": ""
    },
    {
      "id": 5,
      "author_name": "José Antonio Aznar Baeza",
      "author_role": "Product Manager ",
      "author_avatar": "/assets/avatar/jose-antonio-aznar.png",
      "title": "Cloud y sistemas de gestión ISO basados en la nube (I)",
      "created": new Date(2021, 2, 28, 10, 0, 0),
      "modified": new Date(2021, 2, 28, 10, 0, 0),
      "header": "Según el informe Estado actual y futuro del software en España, un estudio de SoftDoit en colaboración con la ATI (Asociación de Técnicos de Informática) en el que han participado más de cien empresas de toda España, la confianza de las empresas españolas en el cloud se situaba en un 60 % en el año 2018.",
      "image": "/assets/articles/cloud-y-sistemas-de-gestion-iso-basados-en-la-nube-i.jpg",
      "comments": 5,
      "likes": 24,
      "dislikes": 0,
      "tags": ["Calidad", "Software", "Ranking"],
      "article": "cloud-y-sistemas-de-gestion-iso-basados-en-la-nube-i",
      "content": ""
    }
  ];

  private contacts = [
    { id: 0, name: 'Zero' },
    { id: 11, name: 'Mr. Nice' },
    { id: 12, name: 'Narco' },
    { id: 13, name: 'Bombasto' },
    { id: 14, name: 'Celeritas' },
    { id: 15, name: 'Magneta' },
    { id: 16, name: 'RubberMan' },
    { id: 17, name: 'Dynama' },
    { id: 18, name: 'Dr IQ' },
    { id: 19, name: 'Magma' },
    { id: 20, name: 'Tornado' }
  ];
  */

  constructor(
    //private http: HttpClient
  ) {
    articles.articles.map(entry=>entry['tagsStr'] = entry.tags.join("\n"))
  }

  createDb() {
    return new Promise((resolve, reject) => {
      resolve({ blog: articles.articles, contacts: contacts.contacts })
      /*
      this.http.get('assets/articles/articles.json', {responseType: 'json'}).subscribe(
        content => {
          console.log(content)
        },
        error => {
          reject({ blog: this.blog, contacts: this.contacts })
        }
      )
      */
    });
  }
}
