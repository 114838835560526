import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterOutlet, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  @ViewChild(MatSidenavContent) sidenavContent: MatSidenavContent;
  @ViewChild(MatToolbar) toolbar: MatToolbar;

  title = 'stsoftware';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private viewportScroller: ViewportScroller,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
  }

  // calculamos que cuando el scroll de la pagina sea tal que queden 100 px para pasar la primera pagina, se cambie el fondo de la barra de herramientas
  ngAfterViewInit() {
    this.sidenavContainer.scrollable.elementScrolled().subscribe(
      (event) => {
        let firstContainer: Element = document.querySelector('#firstContainer');
        if(firstContainer) {
          //console.log(this.sidenavContent.getElementRef().nativeElement.scrollTop, firstContainer.getBoundingClientRect().height, firstContainer)
          this.toolbar._elementRef.nativeElement.classList.toggle('isAbsolute', this.sidenavContent.getElementRef().nativeElement.scrollTop > (firstContainer.getBoundingClientRect().height - 100))
        }
      }
    )
  }

  ngOnInit() {
    /*
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof Scroll)) {
            return;
        }
        //console.log(evt instanceof NavigationEnd);
        //this.sidenavContent.getElementRef().nativeElement.scrollIntoView(true)
        //this.sidenavContainer._content.scrollTo({top: 0});
        if (evt.position) {
          //this.viewportScroller.scrollToPosition(evt.position);
          //this.sidenavContent.getElementRef().nativeElement.scrollTop = evt.position[1];
          this.sidenavContainer._content.scrollTo({top: evt.position[1]});
        } else if (evt.anchor) {
          // this.viewportScroller.scrollToAnchor(evt.anchor);
          //this.sidenavContent.getElementRef().nativeElement.scrollTop = this.sidenavContent.getElementRef().nativeElement.querySelector('#' + evt.anchor).scrollTop;
          //document.querySelector('#' + evt.anchor).clientTop;
          let top = 0;
          for(var el = document.querySelector('#' + evt.anchor) ; el!==null && !el.classList.contains('mat-sidenav-container'); el = el.parentElement) {
            console.log(el, top, el.scrollHeight);
            top += el.scrollHeight;
          }
          this.sidenavContainer._content.scrollTo({top: el.scrollHeight - top});
          // this.sidenavContainer.scrollable.scrollTo({behavior: })
        } else {
          //this.viewportScroller.scrollToPosition([0, 0]);
          //this.sidenavContent.getElementRef().nativeElement.scrollTop = 0;
          this.sidenavContainer._content.scrollTo({top: 0});
        }
        //this.sidenavContent.getElementRef().nativeElement.scrollTop = 0;
      });
    */
  }
}
