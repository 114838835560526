import { Component, OnInit } from '@angular/core';
import { BlogService } from '../blog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { BlogEntry } from '../blog-entry';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  public blogEntry: BlogEntry = null
  public param: string = 'Sin especificar'

  constructor(
    private route: ActivatedRoute,
    private blogSvc: BlogService,
    private http: HttpClient,
    private meta: Meta,
  ) {
    this.param = this.route.snapshot.paramMap.get('article');
    this.blogSvc.getBlogEntryByArticle(this.param).subscribe((entries: BlogEntry[]) => {
      if(entries && entries.length>0) {
        this.blogEntry = entries[0]
        this.http.get('/assets/articles/' + this.param + '.html', {responseType: 'text'}).subscribe(content => {
          this.blogEntry.content = content
        })
        this.blogEntry.metas.push({name: "author_name", content: "José Antonio Aznar Baeza"})
        this.blogEntry.metas.push({name: "title", content: "¿Cómo mejorar tu sistema de gestión de calidad? ¡Utiliza el software adecuado!"})
        this.blogEntry.metas.push({name: "header", content: "El aumento de las exigencias que deben cumplir un sistema de gestión de calidad, unido al hecho de encontrarnos en un entorno cada vez más competitivo, está motivando a las organizaciones a apostar por la informatización de los sistemas de gestión de calidad como forma de optimizar el rendimiento. Pero… ¿Cómo hacerlo? Esa es la gran pregunta a la que suelen enfrentarse los responsables de calidad de las organizaciones."})
        this.blogEntry.metas.push({name: "tags", content: this.blogEntry.tags.join(',')})
        this.meta.addTags(this.blogEntry.metas);
      }
    })
  }

  ngOnInit(): void {
  }
}
