<h2 mat-dialog-title>Contacte con nosotros</h2>
<form [formGroup]="formGroup" #formDir="ngForm" (ngSubmit)="onSubmit();">
  <mat-dialog-content class="mat-typography">
      <div fxLayout="row wrap" fxLayoutGap="1%" [hidden]="formDir.submitted">
        <div fxFlex="1 1 48%">
          <mat-form-field appearance="legacy">
            <mat-label>Nombre de pila</mat-label>
            <mat-hint>Min. 4 car. / Max. 32 car.</mat-hint>
            <input type="text" matInput [formControl]="firstName" placeholder="Ej. Juan" required>
            <!--mat-error *ngIf="firstName.hasError('required')">Este campos es <strong>obligatorio</strong> y su contenido <strong>válido</strong></mat-error-->
          </mat-form-field>
        </div>
        <div fxFlex="1 1 48%">
          <mat-form-field appearance="legacy">
            <mat-label>Apellido(s)</mat-label>
            <input type="text" matInput [formControl]="surname">
          </mat-form-field>
        </div>
        <div fxFlex="1 1 98%">
          <mat-form-field appearance="legacy">
            <mat-label>Empresa a la que representa</mat-label>
            <input type="text" matInput [formControl]="company">
          </mat-form-field>
        </div>
        <div fxFlex="1 1 98%">
          <mat-form-field appearance="legacy">
            <mat-label>Email</mat-label>
            <mat-hint>El email debe ser válido</mat-hint>
            <input type="email" matInput [formControl]="email" required>
            <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">Introduca una dirección de email válida</mat-error>
            <!--mat-error *ngIf="email.hasError('required')">Este campos es <strong>obligatorio</strong></mat-error-->
          </mat-form-field>
        </div>
        <div fxFlex="1 1 98%">
          <mat-form-field appearance="legacy">
            <mat-label>Servicio o solución</mat-label>
            <mat-select [formControl]="cause">
              <mat-option value="" disabled selected>¿Sobre qué servicio o solución realiza su consulta?</mat-option>
              <mat-option value="Desarrollo de software">Desarrollo de software</mat-option>
              <mat-option value="Consultoría Tecnológica">Consultoría Tecnológica</mat-option>
              <mat-option value="Mantenimiento y Soporte">Mantenimiento y Soporte</mat-option>
              <mat-option value="Inteus">Inteus</mat-option>
              <mat-option value="E-reports">E-reports</mat-option>
              <mat-option value="Reforext">Reforext</mat-option>
              <mat-option value="CRM Legal">CRM Legal</mat-option>
              <mat-option value="Audit app">Audit app</mat-option>
              <mat-option value="Kronos app">Kronos app</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="1 1 98%">
          <mat-form-field appearance="legacy">
            <mat-label>Mensaje</mat-label>
            <mat-hint>Min. 20 car.</mat-hint>
            <textarea matInput [formControl]="message" required></textarea>
            <!--mat-error *ngIf="message.hasError('required')">Este campos es <strong>obligatorio</strong></mat-error-->
          </mat-form-field>
        </div>
      </div>
      <h3 class="color-red-600" *ngIf="!formGroup.errors?.isComplete">* Es necesario completar los campos obligatorios antes de remitir el formulario.</h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="submit" mat-raised-button mat-dialog-close color="primary" disableRipple="true" isRoundButton="true" [disabled]="!formGroup.errors?.isComplete">Contactar</button>
  </mat-dialog-actions>
</form>
