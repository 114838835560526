<div id="firstContainer" [style.background]="'linear-gradient(0deg, rgba(30, 30, 30, 0.5), rgba(30, 30, 30, 0.5)), url(\'' + blogEntry.image + '\')'" style="padding: 10vh 15vw;" [style.background-size]="'cover'" [style.background-position]="'center'" *ngIf="blogEntry">
  <div fxLayout="row" fxLayoutAlign="start flex-end">
    <div fxFlex class="color-white">
      <h1 [innerText]="blogEntry?.title"></h1>
    </div>
  </div>
</div>


<div style="padding: 4vh 15vw;" fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="start start">
  <div [innerHtml]="blogEntry?.content" class="color-grey-800"></div>
</div>



