import { Component, OnInit } from '@angular/core';
import { BlogService } from '../blog.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { formatDate } from '@angular/common';
import { BlogEntry } from '../blog-entry';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public entries: BlogEntry[] = [];

  constructor(
    private route: ActivatedRoute,
    private blogSvc: BlogService
  ) {
    console.log(this.route.snapshot)
    if(this.route.snapshot.paramMap.get('author')) {
      this.blogSvc.getBlogEntriesByAuthor(this.route.snapshot.paramMap.get('author')).subscribe((entries:BlogEntry[]) => {
        this.entries = entries.map(entry => {
          return entry
        })
      })
    } else if(this.route.snapshot.paramMap.get('tag')) {
      this.blogSvc.getBlogEntriesByTag(this.route.snapshot.paramMap.get('tag')).subscribe((entries:BlogEntry[]) => {
        this.entries = entries.map(entry => {
          return entry
        })
      })
    } else if(this.route.snapshot.routeConfig.path==="blog/search") {
      this.blogSvc.getBlogEntriesByQuery(this.route.snapshot.queryParams['q']).subscribe((entries:BlogEntry[]) => {
        this.entries = entries.map(entry => {
          return entry
        })
      })
    } else {
      this.blogSvc.getBlogEntries().subscribe((entries:BlogEntry[]) => {
        this.entries = entries.map(entry => {
          return entry
        })
      })
    }
  }

  ngOnInit(): void {
  }
}
