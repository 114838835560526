<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-nav-list>
      <a mat-list-item href="/"><img src="assets/img/logo-50x50.png"></a>
      <a mat-list-item href="services">Servicios</a>
      <a mat-list-item href="solutions">Soluciones</a>
      <a mat-list-item href="we">Nosotros</a>
      <a mat-list-item href="contact">Contacto</a>
      <a mat-list-item href="blog">Blog</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button mat-icon-button nofocus
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <a mat-button
        href="/"
        >
        <img src="assets/img/logo-50x50.png">
      </a>

      <span class="toolbar-spacer"></span>
      <a mat-button
        href="services"
        *ngIf="(isHandset$ | async)===false"
      >
        Servicios
      </a>
      <a mat-button
        href="solutions"
        *ngIf="(isHandset$ | async)===false">
        Soluciones
      </a>
      <a mat-button
        href="we"
        *ngIf="(isHandset$ | async)===false">
        Nosotros
      </a>
      <a mat-button
        href="contact"
        *ngIf="(isHandset$ | async)===false">
        Contacto
      </a>
      <a mat-button
        href="blog"
        *ngIf="(isHandset$ | async)===false">
        Blog
      </a>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
